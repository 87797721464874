import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { graphql, PageProps } from 'gatsby';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';

import { blog as types } from '@codesass/types';

import SEO from 'modules/ui/components/SEO';

import Blog from '../components/Blog';
import { addArticles, setPaging } from '../slice';

export type BlogTemplateData = {
  allMdx: {
    nodes: {
      frontmatter: Pick<
        types.Article,
        'title' | 'excerpt' | 'tags' | 'updatedAt'
      > & {
        image: FileNode;
      };
      slug: string;
    }[];
  };
};

const BlogTemplate: FC<PageProps<BlogTemplateData, types.Pagination>> = ({
  pageContext: paging,
  data: {
    allMdx: { nodes },
  },
}) => {
  const articles = nodes.map(({ frontmatter, slug }) => ({
    ...frontmatter,
    slug,
    image: frontmatter.image,
  }));
  const dispatch = useDispatch();

  dispatch(addArticles(articles));
  dispatch(setPaging(paging));

  return <Blog />;
};

export const query = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: { frontmatter: { contentType: { eq: "article" } } }
      sort: { fields: frontmatter___createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          excerpt
          updatedAt(locale: "th", formatString: "DD MMM YYYY")
          title
          tags
          image {
            childImageSharp {
              gatsbyImageData(quality: 50, width: 567, layout: CONSTRAINED)
            }
          }
        }
        slug
      }
    }
  }
`;

export const Head = () => {
  return (
    <SEO
      title="รวมบทความทั้งหมดของ CODE SASS"
      path="/courses"
      type="article"
      desc="รวมบทความทั้งหมดของ CODE SASS"
    ></SEO>
  );
};

export default BlogTemplate;
