import { useStaticQuery, graphql } from 'gatsby';

import { Box, Container, Typography, Chip } from '@mui/material';

import Link from 'modules/ui/components/Link';

import { TAG_MAPPING } from '../constants';

export type AllTags = {
  allMdx: {
    group: {
      tag: keyof typeof TAG_MAPPING;
    }[];
  };
};

const LatestArticleItem = () => {
  const data = useStaticQuery<AllTags>(graphql`
    query AllTagsQuery {
      allMdx {
        group(field: frontmatter___tags) {
          tag: fieldValue
        }
      }
    }
  `);

  return (
    <>
      <Box py={2} textAlign="center">
        <Container>
          <Typography variant="h4" component="h2">
            บทความ
          </Typography>
          <Typography variant="h6" component="p" color="textSecondary">
            แหล่งรวมบทความทั้งหมดของ CODE SASS
          </Typography>
          {/* {data.allMdx.group.map(({ tag }) => (
            <Link key={tag} href={`/blog/tags/${tag}/p/1`} underline="none">
              <Chip
                label={TAG_MAPPING[tag]}
                sx={{
                  mt: 2,
                  mr: 2,
                  cursor: 'pointer',
                }}
              ></Chip>
            </Link>
          ))} */}
        </Container>
      </Box>
    </>
  );
};

export default LatestArticleItem;
