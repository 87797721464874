import { useSelector } from 'react-redux';

import { Box, Container, Pagination } from '@mui/material';

import { blog as types } from '@codesass/types';

import { RootState } from 'modules/rootReducer';

import { selectAll, selectPaging } from '../selectors';

import ArticleList from './ArticleList';
import BlogHeader from './BlogHeader';

const Blog = () => {
  const articles = useSelector<RootState, types.ArticleListItem[]>(selectAll);
  const paging = useSelector(selectPaging);

  return (
    <>
      <BlogHeader></BlogHeader>
      <ArticleList articles={articles}></ArticleList>
      {paging && (
        <Container>
          <Box display="flex" justifyContent="center" my={2}>
            <Pagination
              aria-label="Page Navigation"
              count={paging.numPages}
              defaultPage={paging.currentPage}
              variant="outlined"
              color="primary"
            ></Pagination>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Blog;
