import { blog as types } from '@codesass/types';

import { RootState } from 'modules/rootReducer';

import { blogAdapter } from './slice';

export const { selectAll, selectById } = blogAdapter.getSelectors<RootState>(
  state => state.blog
);

export const selectPaging = (
  state: RootState
): types.Pagination | undefined => {
  return state.blog.paging;
};

export const selectItem = (
  state: RootState
): types.ArticleDetails | undefined => {
  return state.blog.item;
};
